<template>
  <div class="animated fadeIn">
    <div v-if="error">
      Authentication Error
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        error: false,
      }
    },

    async mounted() {
      const loader = this.$loading.show()
      try {
        const redirectUri = `${window.location.origin}/login/azuread`
        const { code } = this.$route.query
        const { pkce } = this.$store.getters
        const { data } = await this.$request({
          method: 'post',
          url: '/api/auth/azuread/login',
          data: {
            code,
            pkce,
            redirectUri,
          },
        })
        this.$store.commit('authToken', data?.token)
        this.$store.commit('pkce', null)

        // Redirect if we ended up here from a session expire
        try {
          if (this.$store.getters.loginRedirect) {
            const redir = this.$store.getters.loginRedirect
            this.$store.commit('loginRedirect', null)
            return window.location.href = redir
          }
        } catch (e) {
          this.$log.error('Error redirecting user on login', e)
        }

        this.$router.replace('/')
      } catch (e) {
        this.$log.error('Error in Azure AD login', e)
        this.error = true
        this.$toasted.global.error({
          message: e.message,
        })
      } finally {
        loader.hide()
      }
    },
  }
</script>
